<div class="d-flex flex-column" *tuiLet="currentOption$ | async as currentOption">
  <ng-content />
  <ng-container *ngIf="getOptionCountMap$ | executeWith: optionCountMap$ | async" />
  <fnip-list-sidebar-button
    *ngIf="hasCalculatedTotal"
    class="mb-1"
    label="Все"
    [isCurrent]="!currentOption"
    [actionCallback$]="setOption | executeWith: null"
    [hasCount]="hasCount && !!optionCountMap"
    [count]="getTotalCount | executeWith: optionCountMap"
    [useRouting]="useRouting"
    [disableEllipsis]="disableEllipsis"
  />

  <fnip-list-sidebar-button
    *ngFor="let option of getOptions | executeWith: optionMap"
    class="mb-1"
    [label]="optionMap[option]"
    [isCurrent]="(!option && !currentOption) || option === currentOption"
    [btnRouterQueryParams]="getOptionQueryParams | executeWith: option"
    [btnRouterLink]="getOptionLink | executeWith: option"
    [actionCallback$]="setOption | executeWith: option"
    [hasCount]="hasCount && !!optionCountMap"
    [count]="optionCountMap?.[option] ?? 0"
    [useRouting]="useRouting"
    [disableEllipsis]="disableEllipsis"
  />
</div>
