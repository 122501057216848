import { FormControl, FormGroup } from '@angular/forms';
import { TuiDay, TuiDayRange, TuiTime } from '@taiga-ui/cdk';
import { FileInfo, Nullable } from '@lib-utils';
import { ExtendedFormArray } from './extended-form-array';

/**
 * Примитивные типы, которые будут приведены к FormControl
 */
type FormControlPrimitive = number | string | boolean | TuiDay | TuiTime | TuiDayRange | Date | FileInfo;

export type ExtendedFormControlsOf<T> = {
  [key in keyof T]: FormControlOf<T[key], NonNullable<T[key]>>;
};

type FormControlOf<T, TR> = [TR] extends [Array<infer A>]
  ? [NonNullable<A>] extends [FormControlPrimitive]
    ? PrimitiveControl<A[], NonNullable<A>[]>
    : [NonNullable<A>] extends [object]
      ? ExtendedFormArray<FormGroup<ExtendedFormControlsOf<NonNullable<A>>>>
      : never
  : [TR] extends [FormControlPrimitive]
    ? PrimitiveControl<T, TR>
    : [TR] extends [object]
      ? FormGroup<ExtendedFormControlsOf<TR>>
      : PrimitiveControl<T, TR>;

type PrimitiveControl<T, TR> = [Exclude<T, TR>] extends [never] ? FormControl<TR> : FormControl<Nullable<TR>>;
