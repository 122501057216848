import pick from 'lodash-es/pick';
import { VerificationAllDocumentStatus } from '@lib-verification/api-middleware';
import { VerificationDocumentStatusNameMap } from '@lib-verification/utils';

export const VERIFICATOR_STATUS_MAP: Partial<Record<VerificationAllDocumentStatus, string>> = {
  [VerificationAllDocumentStatus.AllTasks]: VerificationDocumentStatusNameMap.AllTasks,
  [VerificationAllDocumentStatus.MyTasks]: VerificationDocumentStatusNameMap.MyTasks,
  [VerificationAllDocumentStatus.ForRevision]: VerificationDocumentStatusNameMap.ForRevision,
  [VerificationAllDocumentStatus.Processed]: VerificationDocumentStatusNameMap.Processed,
  [VerificationAllDocumentStatus.Postponed]: VerificationDocumentStatusNameMap.Postponed,
};

export const VERIFICATOR_SUPERVISOR_STATUS_MAP: Partial<Record<VerificationAllDocumentStatus, string>> = {
  [VerificationAllDocumentStatus.AllTasks]: VerificationDocumentStatusNameMap.AllTasks,
  [VerificationAllDocumentStatus.MyTasks]: VerificationDocumentStatusNameMap.MyTasks,
  [VerificationAllDocumentStatus.ForRevision]: VerificationDocumentStatusNameMap.ForRevision,
  [VerificationAllDocumentStatus.Processed]: VerificationDocumentStatusNameMap.Processed,
  [VerificationAllDocumentStatus.RedirectedToSupervisor]: VerificationDocumentStatusNameMap.RedirectedToSupervisor,
  [VerificationAllDocumentStatus.Archived]: VerificationDocumentStatusNameMap.Archived,
  [VerificationAllDocumentStatus.Postponed]: VerificationDocumentStatusNameMap.Postponed,
};

export const VERIFICATOR_HEAD_STATUS_MAP: Partial<Record<VerificationAllDocumentStatus, string>> = {
  [VerificationAllDocumentStatus.AllTasks]: VerificationDocumentStatusNameMap.AllTasks,
  [VerificationAllDocumentStatus.MyTasks]: VerificationDocumentStatusNameMap.MyTasks,
  [VerificationAllDocumentStatus.ForRevision]: VerificationDocumentStatusNameMap.ForRevision,
  [VerificationAllDocumentStatus.Processed]: VerificationDocumentStatusNameMap.Processed,
  [VerificationAllDocumentStatus.RedirectedToSupervisor]: VerificationDocumentStatusNameMap.RedirectedToSupervisor,
  [VerificationAllDocumentStatus.Pending]: VerificationDocumentStatusNameMap.Pending,
  [VerificationAllDocumentStatus.ApplicationArchived]: VerificationDocumentStatusNameMap.ApplicationArchived,
  [VerificationAllDocumentStatus.Archived]: VerificationDocumentStatusNameMap.Archived,
  [VerificationAllDocumentStatus.Postponed]: VerificationDocumentStatusNameMap.Postponed,
  [VerificationAllDocumentStatus.Pause]: VerificationDocumentStatusNameMap.Pause,
};

export const VERIFICATOR_FILTERS_STATUS_MAP = pick(VerificationDocumentStatusNameMap, [
  'Pending',
  'ForRevision',
  'Processed',
  'Postponed',
]);

export const VERIFICATOR_SUPERVISOR_FILTERS_STATUS_MAP = pick(VerificationDocumentStatusNameMap, [
  'ForRevision',
  'Processed',
  'RedirectedToSupervisor',
  'Archived',
  'Postponed',
]);
