<div
  *tuiLet="[RoleType.VerifierSupervisor, RoleType.VerifierHead] | hasRoles as isVerificatorSupervisorOrVerificatorHead"
  class="d-flex gap-10"
>
  <fnip-list-sidebar
    hasCount
    [initialOption]="filterForm.value.sidebarStatus"
    [optionMap]="getStatusMapByRole | executeWith"
    [optionCountMap$]="statusesCount$"
    (optionChange)="setSidebarStatus($event, isVerificatorSupervisorOrVerificatorHead)"
  />

  <div class="flex-column gap-6 flex-1">
    <fnip-filter
      *ngIf="verificatorRs.hasFirstRs"
      class="mb-6"
      header="Задания"
      [showFilterTags]="true"
      (valueChanges)="grid?.fetchData({ resetPage: true })"
    >
      <fnip-reactive-input
        fnipFilterControl
        fieldId="taskId"
        textfieldIconLeft="tuiIconSearchLarge"
        tagPrefix="ID задания"
        placeholder="ID задания"
        iconAlign="left"
        icon="tuiIconSearchLarge"
        [isNumberInput]="true"
        [control]="filterForm.controls.taskId"
      />
      <fnip-reactive-input
        fnipFilterControl
        fieldId="applicationId"
        textfieldIconLeft="tuiIconSearchLarge"
        tagPrefix="ID заявки"
        placeholder="ID заявки"
        iconAlign="left"
        icon="tuiIconSearchLarge"
        [isNumberInput]="true"
        [control]="filterForm.controls.applicationId"
      />
      <fnip-reactive-input
        fnipFilterControl
        fieldId="lastName"
        iconAlign="left"
        icon="tuiIconSearchLarge"
        tagPrefix="Фамилия"
        placeholder="Фамилия"
        [isCyrillicInput]="true"
        [control]="filterForm.controls.lastName"
      />
      <fnip-reactive-input
        fnipFilterControl
        fieldId="firstName"
        iconAlign="left"
        icon="tuiIconSearchLarge"
        tagPrefix="Имя"
        placeholder="Имя"
        [isCyrillicInput]="true"
        [control]="filterForm.controls.firstName"
      />
      <fnip-reactive-input
        fnipFilterControl
        fieldId="middleName"
        iconAlign="left"
        icon="tuiIconSearchLarge"
        tagPrefix="Отчество"
        placeholder="Отчество"
        [isCyrillicInput]="true"
        [control]="filterForm.controls.middleName"
      />
      <fnip-reactive-button-multi-dropdown
        fnipFilterControl
        fieldId="source"
        tagPrefix="Источник"
        label="Источник"
        appearance="whiteblock"
        [control]="filterForm.controls.sources"
        [options]="sourceOptions"
        [tagDisplayValueCallback]="getOptionLabel | executeWith: sourceOptions"
      />
      <fnip-reactive-button-multi-dropdown
        fnipFilterControl
        fieldId="documentType"
        tagPrefix="Документ"
        label="Документ"
        appearance="whiteblock"
        [control]="filterForm.controls.taskTypes"
        [options]="documentOptions"
        [tagDisplayValueCallback]="getOptionLabel | executeWith: documentOptions"
      />
      <!-- <fnip-reactive-button-multi-dropdown
        #verificatorDropdownRef
        fnipFilterControl
        fieldId="verificatorIds"
        tagPrefix="Верификатор"
        label="Верификатор"
        appearance="whiteblock"
        [control]="filterForm.controls.verificatorIds"
        [options]="getVerificatorOptions$"
        [tagDisplayValueCallback]="getOptionLabel | executeWith: verificatorDropdownRef.optionsMap"
      ></fnip-reactive-button-multi-dropdown> -->
      <fnip-reactive-button-multi-dropdown
        #statusDropdownRef
        *ngIf="
          !filterForm.controls.sidebarStatus.value ||
          filterForm.controls.sidebarStatus.value === VerificationAllDocumentStatus.AllTasks
        "
        fnipFilterControl
        fieldId="documentStatuses"
        label="Статус"
        tagPrefix="Статус"
        appearance="whiteblock"
        fixedWidth
        [control]="filterForm.controls.documentStatuses"
        [options]="statusOptions"
        [tagDisplayValueCallback]="getOptionLabel | executeWith: statusDropdownRef.optionsMap"
        (valueChange)="handleStatusChanges(isVerificatorSupervisorOrVerificatorHead)"
      />
      <fnip-reactive-button-dropdown
        fnipFilterControl
        fieldId="generalTaskType"
        appearance="whiteblock"
        tagPrefix="Тип задания"
        [control]="filterForm.controls.generalTaskType"
        [options]="taskTypeOptions"
        [defaultValue]="VerificationGeneralTaskType.All"
        [tagDisplayValueCallback]="getOptionLabel | executeWith: taskTypeOptions : [VerificationGeneralTaskType.All]"
      />
      <div class="date-range-group">
        <fnip-reactive-input-date-range
          fnipFilterControl
          fieldId="dateRange"
          placeholder="Дата поступления"
          tagPrefix="Дата поступления"
          [control]="filterForm.controls.dateRange"
        />
        <fnip-reactive-input-date-range-select fieldId="dateRangeSelect" [control]="filterForm.controls.dateRange" />
      </div>
    </fnip-filter>

    <ng-container
      *ngIf="
        handleGeneralTaskTypeChange
          | executeWith: grid?.gridOptions?.columnApi : filterForm.controls.generalTaskType?.value
      "
    />
    <fnip-request-wrapper #verificatorRs [request$]="verificator$">
      <fnip-grid
        *ngIf="verificatorRs.hasFirstRs"
        [gridOptions]="gridOptions"
        [page]="page"
        [perPage]="perPage"
        [rowData]="getData$"
        (gridReady)="
          onGridReady(isVerificatorSupervisorOrVerificatorHead);
          handleStatusChanges(isVerificatorSupervisorOrVerificatorHead)
        "
      />
    </fnip-request-wrapper>
  </div>
</div>
