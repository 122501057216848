<ng-container *ngIf="selectInitialOption | executeWith: treeOptions : initialOption" />

<tui-scrollbar *tuiLet="optionCountMap$ | async as optionCountMap" class="tree">
  <ng-container *ngFor="let item of treeOptions">
    <ng-container *ngIf="hasChildOptions | executeWith: item; else rootOptionTmp">
      <fnip-list-sidebar-button
        [label]="item.name"
        [isExpandable]="true"
        [isExpanded]="item.expanded"
        [hasCount]="!!optionCountMap"
        [count]="optionCountMap?.[item.value!]?.value ?? 0"
        [disableEllipsis]="disableEllipsis"
        [actionCallback$]="toggleExpand | executeWith: item"
      />

      <tui-expand [expanded]="!!item.expanded">
        <ng-template tuiExpandContent>
          <ng-container *ngTemplateOutlet="list; context: { item }" />
        </ng-template>
      </tui-expand>
    </ng-container>

    <ng-template #rootOptionTmp>
      <fnip-list-sidebar-button
        class="mb-1"
        [label]="item.name"
        [isCurrent]="currentOption && item.value === currentOption.value"
        [hasCount]="!!optionCountMap"
        [count]="optionCountMap?.[item.value!]?.value ?? 0"
        [useRouting]="useRouting"
        [disableEllipsis]="disableEllipsis"
        [actionCallback$]="setRootOption | executeWith: item.value"
      />
    </ng-template>
  </ng-container>
</tui-scrollbar>

<ng-template #list let-item="item">
  <fnip-list-sidebar
    #listSidebar
    class="flex-1"
    [optionMap]="item.optionsMap"
    [initialOption]="initialOption?.parent?.value === item.value ? initialOption?.value : null"
    [optionCountMap$]="getChildOptionsCount$ | executeWith: optionCountMap$ : item.value"
    [hasCount]="!!optionCountMap$"
    [useRouting]="useRouting"
    [disableEllipsis]="disableEllipsis"
    [queryParamsCallback]="queryParamsCallback | executeWith: item.value"
    (optionChange)="onOptionChange(listSidebar, item, $event)"
  />
</ng-template>
