import { CommonModule } from '@angular/common';
import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { TuiLetModule } from '@taiga-ui/cdk';
import { TuiExpandModule, TuiLinkModule, TuiScrollbarModule } from '@taiga-ui/core';
import { TuiTreeModule } from '@taiga-ui/kit';
import { map, Observable } from 'rxjs';
import { ExecuteWithPipeModule, Nullable } from '@lib-utils';
import { ButtonModule } from '@lib-widgets/core';
import { ListSidebarComponent, ListSidebarModule } from '../list-sidebar';
import { ListSidebarButtonComponent } from '../list-sidebar/list-sidebar-button';
import { TreeOption, TreeOptionChangeEvent, TreeStatusCountMap } from './tree-sidebar.utils';

@Component({
  selector: 'fnip-tree-sidebar',
  standalone: true,
  imports: [
    CommonModule,
    TuiTreeModule,
    ListSidebarModule,
    TuiScrollbarModule,
    TuiExpandModule,
    ButtonModule,
    TuiLinkModule,
    ExecuteWithPipeModule,
    ListSidebarButtonComponent,
    TuiLetModule,
  ],
  templateUrl: './tree-sidebar.component.html',
  styleUrls: ['./tree-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreeSidebarComponent<TOption extends string = string> {
  @ViewChildren(ListSidebarComponent) listSidebars?: QueryList<ListSidebarComponent<TOption>>;

  @Input() useRouting = true;

  @Input({ transform: booleanAttribute }) disableEllipsis = false;

  @Input() treeOptions?: Nullable<TreeOption<TOption>[]>;

  @Input() initialOption: Nullable<TreeOptionChangeEvent<TOption>>;

  @Input() optionCountMap$: Nullable<Observable<TreeStatusCountMap<TOption>>>;

  @Input() parentOptionName: Nullable<string>;

  @Input() childOptionName: Nullable<string>;

  @Output() optionChange = new EventEmitter<Nullable<TreeOptionChangeEvent<TOption>>>();

  currentOption: Nullable<TreeOptionChangeEvent<TOption>>;

  selectInitialOption = (
    treeOptions: Nullable<TreeOption<TOption>[]>,
    initialOption: Nullable<TreeOptionChangeEvent<TOption>>,
  ) => {
    if (!initialOption || !treeOptions?.length || this.currentOption?.value === initialOption.value) return;
    this.currentOption = initialOption;
    const currentTreeOption = this.treeOptions?.find(
      (item) =>
        item.value === initialOption.parent?.value && Object.keys(item.optionsMap).includes(initialOption.value!),
    );
    if (currentTreeOption) currentTreeOption.expanded = true;
  };

  getChildOptionsCount$ = (optionsCount$: Nullable<Observable<TreeStatusCountMap<TOption>>>, parent: TOption) =>
    optionsCount$?.pipe(map((statusCount) => statusCount[parent]?.children ?? {}));

  hasChildOptions = (option: TreeOption<TOption>) => Object.values(option.optionsMap).length > 0;

  onOptionChange = (
    sidebar: Nullable<ListSidebarComponent<TOption>>,
    parentOption: Nullable<TreeOption<TOption>>,
    childoption: Nullable<TOption>,
  ) => {
    this.listSidebars?.filter((item) => item !== sidebar).forEach((item) => item.clearCurrentOption());
    const option = childoption ? { value: childoption, parent: parentOption } : null;
    this.currentOption = option;
    this.optionChange.emit(option);
  };

  queryParamsCallback = (parentValue: Nullable<TOption>) => (childValue: Nullable<TOption>) => {
    return {
      [this.parentOptionName ?? 'state']: parentValue ?? null,
      [this.childOptionName ?? 'status']: childValue ?? null,
    };
  };

  setRootOption = (value: Nullable<TOption>) => () => {
    this.onOptionChange(null, null, value);
  };

  toggleExpand = (option: TreeOption<TOption>) => () => {
    option.expanded = !option.expanded;
  };
}
