import { ColumnApi, GridOptions } from '@ag-grid-community/core';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TuiDayRange, TuiLetModule } from '@taiga-ui/cdk';
import omit from 'lodash-es/omit';
import { EMPTY, of } from 'rxjs';
import { filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { ExtendedFormGroup } from '@lib-form';
import {
  beToFeOptions,
  byInterval,
  createOptionsForLocalizedEnum,
  ExecuteWithPipeModule,
  FilterStateOrder,
  FilterStateService,
  FormControlsOfNew,
  getRouteParams,
  NotificationService,
  Nullable,
  RoleType,
  SessionStorageFilterStateService,
  toTuiRange,
} from '@lib-utils';
import { DialogService, injectDialogService, SetUserDialogComponent } from '@lib-widgets/dialog';
import { FilterModule, getOptionLabel } from '@lib-widgets/filter';
import {
  dateTimeCellDef,
  exactWidth,
  getActionCellDef,
  GridComponent,
  GridGetDataCallback,
  GridModule,
  tagCellDef,
} from '@lib-widgets/grid';
import { ListSidebarModule } from '@lib-widgets/menu';
import {
  ReactiveButtonDropdownModule,
  ReactiveButtonMultiDropdownModule,
  ReactiveInputDateRangeModule,
  ReactiveInputDateRangeSelectComponent,
  ReactiveInputModule,
} from '@lib-widgets/reactive-fields';
import { RequestWrapperModule } from '@lib-widgets/request-wrapper';
import { AuthorizationStorageService, HasRolesPipe } from '@lib-mortgage/features/authorization';
import {
  VerificationApiService,
  VerificationTaskInfoDto,
  VerificationTaskSortFields,
  VerificationTaskType,
  VerifierInfoDto,
  VerifierType,
} from '@lib-verification/api';
import {
  CallTaskTypeMap,
  VerificationAllDocumentStatus,
  VerificationGeneralTaskType,
} from '@lib-verification/api-middleware';
import {
  VerificationDocumentStatusNameMap,
  VerificationSourceTypeMap,
  VerificatorGeneralTaskTypeMap,
  VerificatorTaskTypeNameMap,
} from '@lib-verification/utils';
import { IDENTICAL_TASK_TYPES_MAP, OperatorFilters } from './operator-filters.utils';
import {
  VERIFICATOR_FILTERS_STATUS_MAP,
  VERIFICATOR_HEAD_STATUS_MAP,
  VERIFICATOR_STATUS_MAP,
  VERIFICATOR_SUPERVISOR_FILTERS_STATUS_MAP,
  VERIFICATOR_SUPERVISOR_STATUS_MAP,
} from './status-map';

const createFilterForm = (destroyRef: DestroyRef, data?: Nullable<OperatorFilters>) =>
  new ExtendedFormGroup(
    {
      taskId: new FormControl(data?.taskId),
      applicationId: new FormControl(data?.applicationId),
      lastName: new FormControl(data?.lastName),
      firstName: new FormControl(data?.firstName),
      middleName: new FormControl(data?.middleName),
      sources: new FormControl(data?.sources ?? [], { nonNullable: true }),
      taskTypes: new FormControl([], { nonNullable: true }),
      verificatorIds: new FormControl<number[]>(data?.verificatorIds ?? [], { nonNullable: true }),
      sidebarStatus: new FormControl<Nullable<VerificationAllDocumentStatus>>(VerificationAllDocumentStatus.AllTasks),
      documentStatuses: new FormControl(data?.documentStatuses ?? [], { nonNullable: true }),
      generalTaskType: new FormControl(VerificationGeneralTaskType.All, {
        nonNullable: true,
      }),
      dateRange: new FormControl<Nullable<TuiDayRange>>(null),
    } satisfies FormControlsOfNew<OperatorFilters>,
    { destroyRef },
  );

@Component({
  selector: 'fnip-operator-list',
  standalone: true,
  templateUrl: './operator-list.component.html',
  styleUrls: ['./operator-list.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    DialogService,
    {
      provide: FilterStateService,
      useClass: SessionStorageFilterStateService,
    },
  ],
  imports: [
    CommonModule,
    ExecuteWithPipeModule,
    ListSidebarModule,
    FilterModule,
    ReactiveButtonDropdownModule,
    ReactiveButtonMultiDropdownModule,
    ReactiveInputDateRangeModule,
    ReactiveInputDateRangeSelectComponent,
    ReactiveInputModule,
    GridModule,
    RequestWrapperModule,
    TuiLetModule,
    HasRolesPipe,
  ],
})
export class OperatorListComponent implements OnInit {
  @ViewChild(GridComponent) grid?: GridComponent;

  page = 1;
  perPage = 10;
  initialSort?: FilterStateOrder;

  readonly RoleType = RoleType;
  readonly getOptionLabel = getOptionLabel;
  readonly VerificationGeneralTaskType = VerificationGeneralTaskType;
  readonly VerificationAllDocumentStatus = VerificationAllDocumentStatus;

  readonly hasRoles = inject(AuthorizationStorageService).hasRoles;
  readonly queryStatus = getRouteParams<{ status?: VerificationAllDocumentStatus }>().status;
  readonly verificationApiService = inject(VerificationApiService);
  readonly filterStateService = inject<FilterStateService<typeof this.filterForm.value>>(FilterStateService);
  readonly dialogService = injectDialogService();
  readonly notificationService = inject(NotificationService);

  readonly verificator$ = this.verificationApiService.apiVerificationVerifierGet().pipe(shareReplay(1));
  readonly filterForm = createFilterForm(inject(DestroyRef));

  readonly statusesCount$ = byInterval(
    this.verificator$.pipe(
      switchMap((verificator) =>
        verificator ? this.verificationApiService.apiVerificationTasksStatusesCountGet() : EMPTY,
      ),
      map((res) => res?.statusesCount!),
    ),
  );

  readonly gridOptions: GridOptions<VerificationTaskInfoDto> = {
    context: this,
    defaultColDef: {
      sortable: true,
      initialFlex: 1,
      minWidth: 200,
    },
    columnDefs: [
      {
        field: 'id',
        headerName: 'ID задания',
        headerClass: 'text-center',
        cellClass: 'text-center',
        initialPinned: 'left',
        initialSort: 'desc',
        lockPinned: true,
        ...exactWidth(115),
      },
      {
        field: 'applicationId',
        headerName: 'ID заявки',
        headerClass: 'text-center',
        cellClass: 'text-center',
        initialPinned: 'left',
        lockPinned: true,
        sortable: false,
        ...exactWidth(110),
      },
      {
        field: 'fullName',
        headerName: 'ФИО',
        sortable: false,
        valueFormatter: ({ value }) => value?.toUpperCase(),
        width: 300,
        flex: 0,
      },
      {
        colId: 'documentOrCallType',
        headerName: 'Документ / Тип звонка',
        valueGetter: ({ data }) => {
          if (data?.type === VerificationTaskType.Call && data?.callType) return CallTaskTypeMap[data.callType];
          if (data?.type) return VerificatorTaskTypeNameMap[data.type]!;
          return '';
        },
      },
      {
        field: 'type',
        colId: 'documentType',
        headerName: 'Документ',
        refData: VerificatorTaskTypeNameMap,
      },
      {
        field: 'callType',
        colId: 'callType',
        headerName: 'Тип звонка',
        sortable: false,
        refData: CallTaskTypeMap,
      },
      {
        field: 'type',
        colId: 'taskType',
        headerName: 'Тип задания',
        valueFormatter: ({ value }) =>
          value === VerificationTaskType.Call ? 'Верификация номера' : 'Верификация документа',
      },
      {
        field: 'status',
        colId: 'documentStatus',
        headerName: 'Статус',
        ...tagCellDef({
          styles: {
            'background-color': '#ebebeb',
            color: '#44475d',
          },
          labelMap: VerificationDocumentStatusNameMap,
        }),
      },
      {
        field: 'created',
        headerName: 'Дата поступления',
        ...dateTimeCellDef(),
      },
      {
        field: 'verifierName',
        headerName: 'Верификатор',
        sortable: false,
      },
      {
        colId: 'action',
        ...getActionCellDef<VerificationTaskInfoDto>({
          getIcon: (data) => {
            if (this.hasRoles([RoleType.VerifierSupervisor, RoleType.VerifierHead]))
              return data?.status === VerificationAllDocumentStatus.RedirectedToSupervisor
                ? 'tuiIconEdit2'
                : 'tuiIconEye';

            return data?.status === VerificationAllDocumentStatus.Pending ? 'tuiIconEdit2' : 'tuiIconEye';
          },
          getHint: (data) => {
            if (this.hasRoles([RoleType.VerifierSupervisor, RoleType.VerifierHead]))
              return data?.status === VerificationAllDocumentStatus.RedirectedToSupervisor
                ? 'Взять в работу'
                : 'Просмотр';

            return data?.status === VerificationAllDocumentStatus.Pending ? 'Взять в работу' : 'Просмотр';
          },
          getLink: (data) => [data?.id ?? ''],
        }),
      },
      {
        colId: 'assignToVerificatorAction',
        ...getActionCellDef<VerificationTaskInfoDto>({
          icon: 'tuiIconExternalLink',
          hint: 'Назначить',
          getAction$: (data) => () => this.getSetVerificatorAction$(data),
          isDisabledCallback$: (data) => of(!!data?.verifierId),
        }),
      },
      {
        colId: 'allTasksAction',
        initialPinned: 'right',
        lockPinned: true,
        ...getActionCellDef<VerificationTaskInfoDto>({
          icon: 'tuiIconEye',
          hint: 'Просмотр',
          getLink: (data) => [data?.id ?? ''],
        }),
      },
    ],
  };

  getCurrentRole = () => {
    if (this.hasRoles([RoleType.SuperAdmin])) return RoleType.SuperAdmin;
    if (this.hasRoles([RoleType.VerifierHead])) return RoleType.VerifierHead;
    if (this.hasRoles([RoleType.VerifierSupervisor])) return RoleType.VerifierSupervisor;
    return RoleType.Verificator;
  };

  getStatusOptionsByRole = () => {
    switch (this.getCurrentRole()) {
      case RoleType.SuperAdmin:
      case RoleType.VerifierHead:
        return VerificationDocumentStatusNameMap;
      case RoleType.VerifierSupervisor:
        return VERIFICATOR_SUPERVISOR_FILTERS_STATUS_MAP;
      default:
        return VERIFICATOR_FILTERS_STATUS_MAP;
    }
  };

  // readonly getVerificatorOptions$ = this.verificationApiService
  //   .apiVerificationVerificatorsGet()
  //   .pipe(map((res) => beToFeOptions(res.data, { labelPath: 'fullName' })));
  readonly sourceOptions = createOptionsForLocalizedEnum(VerificationSourceTypeMap);
  readonly taskTypeOptions = createOptionsForLocalizedEnum(VerificatorGeneralTaskTypeMap);
  readonly statusOptions = createOptionsForLocalizedEnum(this.getStatusOptionsByRole()).filter(
    (item) => item.value !== VerificationAllDocumentStatus.AllTasks,
  );
  readonly documentOptions = beToFeOptions(
    Object.values(VerificationTaskType)
      .filter(
        (item) => ![...IDENTICAL_TASK_TYPES_MAP.values()].flat().includes(item) && VerificatorTaskTypeNameMap[item],
      )
      .map((value) => {
        return {
          value: value,
          name: VerificatorTaskTypeNameMap[value]!,
        };
      })
      .sort((a, b) => {
        if (a.name.includes('14-15') && (b.name.includes('4-13') || b.name.includes('2-3'))) return 1;
        return a.name > b.name ? 1 : -1;
      }),
  );

  ngOnInit() {
    const {
      filters: { dateRange, ...filters },
      pagination: { page, perPage },
      order,
    } = this.filterStateService.getState();
    this.page = page;
    this.perPage = perPage;
    // Сохраняем сортировку и ждем готовности грида, чтобы ее задать
    this.initialSort = order;

    this.filterForm.patchValue({
      ...(this.queryStatus
        ? {
            sidebarStatus: this.queryStatus ?? VerificationAllDocumentStatus.AllTasks,
            documentStatuses: [],
          }
        : {}),
      ...filters,
      dateRange: dateRange ? toTuiRange(dateRange.from as string, dateRange.to as string) : undefined,
    });
  }

  onGridReady = (isVerificatorSupervisorOrVerificatorHead: boolean) => {
    if (this.initialSort) {
      this.grid?.setOrder(this.initialSort);
    }

    this.handleStatusChanges(isVerificatorSupervisorOrVerificatorHead);
  };

  setSidebarStatus(status: Nullable<VerificationAllDocumentStatus>, isVerificatorSupervisorOrVerificatorHead: boolean) {
    this.filterForm.controls.documentStatuses.reset();
    this.filterForm.controls.sidebarStatus.setValue(status);
    this.grid?.fetchData({ resetPage: true });
    this.handleStatusChanges(isVerificatorSupervisorOrVerificatorHead);
  }

  getData$: GridGetDataCallback = (pagination, order) => {
    this.filterStateService.updateState(this.filterForm.value, pagination, order);
    const filters = OperatorFilters.mapData(this.filterForm.getRawValue(), this.getCurrentRole());

    return this.verificationApiService.apiVerificationTasksGet({
      page: pagination.page,
      perPage: pagination.perPage,
      sortFieldsOrderBy: order?.orderBy,
      sortFieldsFieldBy:
        order?.fieldBy === 'DocumentOrCallType' ? 'DocumentType' : (order?.fieldBy as VerificationTaskSortFields),
      filters: { filters },
    });
  };

  getStatusMapByRole = () => {
    switch (this.getCurrentRole()) {
      case RoleType.SuperAdmin:
        return omit(VERIFICATOR_HEAD_STATUS_MAP, VerificationAllDocumentStatus.MyTasks);
      case RoleType.VerifierHead:
        return VERIFICATOR_HEAD_STATUS_MAP;
      case RoleType.VerifierSupervisor:
        return VERIFICATOR_SUPERVISOR_STATUS_MAP;
      default:
        return VERIFICATOR_STATUS_MAP;
    }
  };

  getSetVerificatorAction$ = (task: Nullable<VerificationTaskInfoDto>) =>
    this.dialogService
      .open<boolean>(SetUserDialogComponent<VerifierInfoDto>, {
        contextData: {
          title: `Верификатор для задачи № ${task?.id}`,
          initialValue: { id: task?.verifierId, fullName: task?.verifierName },
          selectPlaceholder: 'Верификатор',
          actionLabel: 'Назначить',
          actionCallback$: (verifierId: number) => {
            return this.verificationApiService
              .apiVerificationTaskTaskIdTransitionsAssignTaskToVerifierVerifierIdPost({
                verifierId,
                taskId: task?.id!,
              })
              .pipe(
                tap(this.notificationService.onSuccess('Удалось назначить верификатора')),
                tap(this.notificationService.onError('Не удалось назначить верификатора')),
              );
          },
          options: this.verificationApiService
            .apiVerificationVerifiersGet({
              filters: { filters: { VerifierType: VerifierType.General, LessThanTasksCount: '3' } },
            })
            .pipe(map((resp) => beToFeOptions(resp ?? [], { labelPath: 'fullName' }))),
        },
      })
      .pipe(
        filter(Boolean),
        tap(() => this.grid?.fetchData()),
      );

  handleGeneralTaskTypeChange(columnApi: Nullable<ColumnApi>, generalTaskType: Nullable<VerificationGeneralTaskType>) {
    columnApi?.applyColumnState({
      state: [
        { colId: 'documentOrCallType', hide: generalTaskType !== VerificationGeneralTaskType.All },
        { colId: 'documentType', hide: generalTaskType !== VerificationGeneralTaskType.Document },
        { colId: 'callType', hide: generalTaskType !== VerificationGeneralTaskType.Call },
      ],
    });
  }

  handleStatusChanges(isVerificatorSupervisorOrVerificatorHead: boolean) {
    const sidebarStatus = this.filterForm.controls.sidebarStatus.value;
    const statuses = this.filterForm.controls.documentStatuses.value;

    const hasStatus = (status: VerificationAllDocumentStatus) => {
      if (!sidebarStatus || (sidebarStatus === VerificationAllDocumentStatus.AllTasks && statuses?.length)) {
        return statuses?.includes(status);
      }

      return sidebarStatus === status;
    };

    const pendingStatusForVerificatorHeadShown =
      hasStatus(VerificationAllDocumentStatus.Pending) && this.hasRoles([RoleType.VerifierHead]);

    this.gridOptions.columnApi?.applyColumnState({
      state: [
        {
          colId: 'documentStatus',
          hide: isVerificatorSupervisorOrVerificatorHead
            ? hasStatus(VerificationAllDocumentStatus.RedirectedToSupervisor)
            : hasStatus(VerificationAllDocumentStatus.Pending),
        },
        {
          colId: 'comment',
          hide:
            !hasStatus(VerificationAllDocumentStatus.ForRevision) ||
            !hasStatus(VerificationAllDocumentStatus.Archived) ||
            !hasStatus(VerificationAllDocumentStatus.ApplicationArchived),
        },
        {
          colId: 'verifierName',
          hide: !pendingStatusForVerificatorHeadShown,
        },
        {
          colId: 'allTasksAction',
          hide: !(
            hasStatus(VerificationAllDocumentStatus.AllTasks) ||
            hasStatus(VerificationAllDocumentStatus.Archived) ||
            hasStatus(VerificationAllDocumentStatus.ApplicationArchived)
          ),
        },
        {
          colId: 'action',
          hide:
            hasStatus(VerificationAllDocumentStatus.AllTasks) ||
            hasStatus(VerificationAllDocumentStatus.Archived) ||
            hasStatus(VerificationAllDocumentStatus.ApplicationArchived),
        },
        {
          colId: 'assignToVerificatorAction',
          hide: !pendingStatusForVerificatorHeadShown,
        },
      ],
    });
  }
}
