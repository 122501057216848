import { FromGeneratedEnum, Nullable } from '@lib-utils';

export interface TreeOption<TOption extends string = string> {
  name?: string;
  value?: TOption;
  optionsMap: FromGeneratedEnum<TOption>;
  expanded?: boolean;
}

interface TreeOptionCount<TOption extends string = string> {
  value?: number;
  children?: Partial<Record<TOption, number>>;
}

export type TreeStatusCountMap<TOption extends string = string> = Partial<Record<TOption, TreeOptionCount>>;

export interface TreeOptionChangeEvent<TOption extends string> {
  parent?: Nullable<TreeOption<TOption>>;
  value: Nullable<TOption>;
}
