import {
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
  TemplateRef,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import truncate from 'lodash-es/truncate';
import { combineLatest, debounceTime, map, skip, startWith } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DEBOUNCE_TIME_DEFAULT, Nullable } from '@lib-utils';
import { ButtonComponent } from '@lib-widgets/core';
import { FilterControlDirective } from './filter-control.directive';

@Component({
  selector: 'fnip-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterComponent {
  @Input() header: Nullable<string>;

  @Input() showFilterTags = false;

  @Input() actionsTemplate: Nullable<TemplateRef<unknown>>;

  @Input() actionLabel: ButtonComponent['label'];

  @Input() actionCallback$: ButtonComponent['actionCallback$'];

  @Input() actionRouterLink: ButtonComponent['btnRouterLink'];

  @Input() actionIcon: ButtonComponent['icon'];

  @Input() form: Nullable<FormGroup>;

  @Output() valueChanges = new EventEmitter<void>();

  @ContentChildren(FilterControlDirective, { descendants: true }) filtersList?: QueryList<FilterControlDirective>;

  TAG_VALUE_LENGTH = 40;

  getFiltersArray$ = (filters?: QueryList<FilterControlDirective>) => {
    return filters?.changes.pipe(
      startWith(null),
      map(() => filters?.toArray() || []),
    );
  };

  getTagValue = (filter: FilterControlDirective, tagIndex: number, displayValue: unknown) => {
    const prefix = filter.getTagPrefix(tagIndex, displayValue);
    return truncate(prefix ? `${prefix}: ${displayValue}` : `${displayValue}`, { length: this.TAG_VALUE_LENGTH });
  };

  resetFilters = (filters: FilterControlDirective[]) => () => {
    filters.forEach((filter) => filter.reset(null));
  };

  valueChanges$ = (filters: FilterControlDirective[], form: Nullable<FormGroup>) => {
    const additionalControlsLastValue = this.getAdditionalControlsFromForm(filters, form).map((control) =>
      control.valueChanges.pipe(startWith(control.value)),
    );

    return combineLatest([...filters.map((filter) => filter.lastValue$), ...additionalControlsLastValue]).pipe(
      skip(1),
      debounceTime(DEBOUNCE_TIME_DEFAULT),
      tap(() => this.valueChanges.emit()),
    );
  };

  hasFilters$ = (filters: FilterControlDirective[]) =>
    combineLatest(filters.map((filter) => filter.hasValue$)).pipe(map((results) => results.some(Boolean)));

  private getAdditionalControlsFromForm(filters: FilterControlDirective[], form: Nullable<FormGroup>) {
    if (!form) return [];

    const formControls = Object.values(form.controls);
    const filterControls = filters.map((filter) => filter.control);

    return formControls.filter((control) => !filterControls.includes(control as FormControl));
  }
}
