<ng-container *ngIf="update$ | async" />

<div class="d-flex flex-1 content-end align-items-center gap-3">
  <span class="font-size-18 font-weight-bold">Статус сотрудника:</span>
  <fnip-request-wrapper #rs [request$]="getData$ | executeWith: verificator$" appearance="inline">
    <fnip-reactive-button-dropdown
      *ngIf="rs.hasFirstRs"
      fieldId="verificatorStatus"
      appearance="whiteblock"
      label="Статус сотрудника:"
      [showBadge]="false"
      [options]="options"
      [control]="verificatorStatusControl"
      (valueChange)="update$ = updateStatus$?.(rs.data?.id, $event) ?? null"
    />
  </fnip-request-wrapper>
</div>
