import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TuiLetModule } from '@taiga-ui/cdk';
import { ExecuteWithPipeModule } from '@lib-utils';
import { ListSidebarButtonComponent } from './list-sidebar-button';
import { ListSidebarComponent } from './list-sidebar.component';

@NgModule({
  imports: [CommonModule, ExecuteWithPipeModule, TuiLetModule, ListSidebarButtonComponent],
  exports: [ListSidebarComponent],
  declarations: [ListSidebarComponent],
})
export class ListSidebarModule {}
