import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TuiLetModule } from '@taiga-ui/cdk';
import { TuiTagModule } from '@taiga-ui/kit';
import { ExecuteWithPipeModule } from '@lib-utils';
import { ButtonModule } from '@lib-widgets/core';
import { FilterControlDirective } from './filter-control.directive';
import { FilterComponent } from './filter.component';

@NgModule({
  imports: [FilterControlDirective, CommonModule, ButtonModule, ExecuteWithPipeModule, TuiLetModule, TuiTagModule],
  declarations: [FilterComponent],
  exports: [FilterComponent, FilterControlDirective],
})
export class FilterModule {}
