import { DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  AbstractControlOptions,
  FormGroup,
  ɵFormGroupRawValue,
  ɵFormGroupValue,
} from '@angular/forms';
import isEqual from 'lodash-es/isEqual';
import isNil from 'lodash-es/isNil';
import { BehaviorSubject, tap } from 'rxjs';
import { Nullable, omitNullable } from '@lib-utils';

export interface ExtendedFormGroupOptions extends AbstractControlOptions {
  isDisabled?: boolean;
  destroyRef: DestroyRef; // для удаления внутренней подписки
}

export class ExtendedFormGroup<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TControl extends { [K in keyof TControl]: AbstractControl<any> } = any,
> extends FormGroup<TControl> {
  public get initialValue() {
    return this._initialValue.value;
  }

  public set initialValue(value: Nullable<ɵFormGroupValue<TControl>>) {
    this._initialValue.next(value);
  }

  public get hasChanges() {
    return this._wasValueChanged;
  }

  public get rawValue() {
    return this._lastRawValue;
  }

  private _wasValueChanged = false;
  private _initialValue: BehaviorSubject<Nullable<ɵFormGroupValue<TControl>>>;
  private _lastRawValue: Nullable<ɵFormGroupRawValue<TControl>>;

  constructor(controls: TControl, { isDisabled, destroyRef, ...abstractControlOptions }: ExtendedFormGroupOptions) {
    super(controls, abstractControlOptions);

    if (isDisabled) this.disable();
    this._initialValue = new BehaviorSubject(this.getRawValue() as Nullable<ɵFormGroupValue<TControl>>);
    this.subscribeOnValue(destroyRef);
  }

  public resetToInitialValue = () => {
    if (!this.initialValue) return;
    this.reset(this.initialValue);
  };

  override patchValue = (
    value: ɵFormGroupValue<TControl>,
    options?: {
      onlySelf?: boolean;
      emitEvent?: boolean;
      updateInitialValue?: boolean;
    },
  ) => {
    super.patchValue(value, options);
    if (options?.updateInitialValue)
      this.initialValue = omitNullable(this.getRawValue()) as Nullable<ɵFormGroupRawValue<TControl>>;
    this.updateValuesAndChanges();
  };

  override reset = (
    value?: ɵFormGroupValue<TControl> | TControl,
    options?: {
      onlySelf?: boolean;
      emitEvent?: boolean;
    },
  ) => {
    super.reset(omitNullable(value) as ɵFormGroupValue<TControl>, options);
    this.updateValuesAndChanges();
  };

  private updateValuesAndChanges() {
    const value = omitNullable(this.getRawValue());
    this._wasValueChanged = !isNil(this.initialValue) && !isEqual(this.initialValue, value);
    this._lastRawValue = value as ɵFormGroupRawValue<TControl>;
  }

  private subscribeOnValue(destroyRef: DestroyRef) {
    this.valueChanges
      .pipe(
        tap(() => this.updateValuesAndChanges()),
        takeUntilDestroyed(destroyRef),
      )
      .subscribe();
  }
}
