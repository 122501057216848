<ng-container *ngIf="getFiltersArray$ | executeWith: filtersList | async as filters">
  <ng-container *ngIf="valueChanges$ | executeWith: filters : form | async" />

  <div class="header d-flex gap-4">
    <ng-container *ngIf="header">
      <span class="header__title">{{ header }}</span>
      <ng-container [ngTemplateOutlet]="clearFiltersTmp" />
    </ng-container>

    <div *ngIf="actionsTemplate">
      <ng-container [ngTemplateOutlet]="actionsTemplate" />
    </div>

    <fnip-button
      *ngIf="(actionCallback$ || actionRouterLink) && !actionsTemplate"
      appearance="primary"
      [label]="actionLabel"
      [icon]="actionIcon"
      [actionCallback$]="actionCallback$"
      [btnRouterLink]="actionRouterLink"
    />
  </div>

  <div *ngIf="showFilterTags" class="tags d-flex gap-4 flex-wrap flex-1">
    <ng-container *ngFor="let filter of filters">
      <ng-container *ngFor="let displayValue of filter.displayValues$ | async; let tagIndex = index">
        <ng-container *ngIf="displayValue && !filter.customTagTemplate">
          <tui-tag
            class="tags__tag"
            size="l"
            [value]="getTagValue | executeWith: filter : tagIndex : displayValue"
            [removable]="true"
            [hoverable]="true"
            (edited)="filter.reset(tagIndex)"
          />
        </ng-container>

        <div *ngIf="displayValue && filter.customTagTemplate" class="tags__tag">
          <ng-template
            [ngTemplateOutlet]="filter.customTagTemplate"
            [ngTemplateOutletContext]="{ filter, displayValue, tagIndex }"
          />
        </div>
      </ng-container>
    </ng-container>
  </div>

  <div class="filters">
    <ng-content />
    <ng-container *ngIf="!header" [ngTemplateOutlet]="clearFiltersTmp" />
  </div>

  <ng-template #clearFiltersTmp>
    <fnip-button
      *ngIf="hasFilters$ | executeWith: filters | async"
      icon="tuiIconFilter"
      label="Очистить"
      [actionCallback$]="resetFilters | executeWith: filters"
    />
  </ng-template>
</ng-container>
