import { booleanAttribute, ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { FromGeneratedEnum, Nullable } from '@lib-utils';
import { ButtonComponent } from '@lib-widgets/core';
import { getTotalCount } from './list-sidebar.utils';

@Component({
  selector: 'fnip-list-sidebar',
  templateUrl: './list-sidebar.component.html',
  styleUrls: ['./list-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListSidebarComponent<T extends string = string> {
  @Input({ required: true }) optionMap!: FromGeneratedEnum<T>;

  @Input() set initialOption(value: Nullable<T>) {
    this.currentOption$.next(value);
  }

  @Input() queryParamsCallback: Nullable<(option: T) => Record<string, Nullable<string>>>;

  @Input() optionLinkCallback: Nullable<(option: T) => ButtonComponent['btnRouterLink']>;

  @Input() optionCountMap$: Nullable<Observable<Partial<Record<T, number>>>>;

  @Input() useRouting = true;

  @Input({ transform: booleanAttribute }) disableEllipsis = false;

  @Input({ transform: booleanAttribute }) hasCount = false;

  @Input({ transform: booleanAttribute }) hasCalculatedTotal = false;

  readonly getTotalCount = getTotalCount;

  @Input() optionName = 'status';

  @Output() optionChange = new EventEmitter<Nullable<T>>();

  optionCountMap: Nullable<Partial<Record<T, number>>>;

  currentOption$ = new BehaviorSubject<Nullable<T>>(null);

  getOptions = (optionMap: FromGeneratedEnum<T>) => [...Object.keys(optionMap)] as T[];

  getOptionCountMap$ = (optionMapRq$: Nullable<Observable<Partial<Record<T, number>>>>) =>
    optionMapRq$?.pipe(tap((optionMap) => (this.optionCountMap = optionMap)));

  getOptionQueryParams = (option: T) => {
    if (this.queryParamsCallback) return this.queryParamsCallback(option);
    return { [this.optionName]: option };
  };

  getOptionLink = (option: T) => {
    if (this.optionLinkCallback) return this.optionLinkCallback(option);
    return ['.'];
  };

  setOption = (option: Nullable<T>) => () => {
    this.currentOption$.next(option);
    this.optionChange.emit(option);
  };

  public clearCurrentOption() {
    this.currentOption$.next(null);
  }
}
