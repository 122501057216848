import {
  AbstractControl,
  AbstractControlOptions,
  AsyncValidatorFn,
  FormArray,
  ValidatorFn,
  ɵFormArrayValue,
} from '@angular/forms';
import { Nullable } from '@lib-utils';

type MakeItemControlFn<T, TControl> = (data: T) => TControl;

interface ExtendedFormArrayOptions<T, TControl> {
  makeControlFn?: MakeItemControlFn<T, TControl>;
}

export class ExtendedFormArray<TControl extends AbstractControl> extends FormArray<TControl> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  makeControlFn?: MakeItemControlFn<any, TControl>;

  constructor(
    controls: Array<TControl>,
    validatorOrOpts?:
      | ValidatorFn
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      | (AbstractControlOptions & ExtendedFormArrayOptions<any, TControl>)
      | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null,
  ) {
    super(controls, validatorOrOpts, asyncValidator);
    if (validatorOrOpts && !Array.isArray(validatorOrOpts) && typeof validatorOrOpts !== 'function') {
      this.makeControlFn = validatorOrOpts.makeControlFn;
    }
  }

  public appendControl(value?: TControl['value']) {
    if (!this.makeControlFn) return;
    this.push(this.makeControlFn(value));
  }

  override patchValue(
    value: Nullable<ɵFormArrayValue<TControl>>,
    options?: { onlySelf?: boolean; emitEvent?: boolean; onlyPatch?: boolean },
  ) {
    if (!options?.onlyPatch) this.adjustControls(value, options);
    super.patchValue(value || [], options);
  }

  override reset(value?: ɵFormArrayValue<TControl>, options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    this.adjustControls(value, options);
    super.reset(value, options);
  }

  private adjustControls(
    value: Nullable<ɵFormArrayValue<TControl>>,
    options?: { onlySelf?: boolean; emitEvent?: boolean },
  ) {
    const valueLength = value?.length ?? 0;
    const controlsLength = this.controls.length;

    if (this.makeControlFn && valueLength > controlsLength) {
      for (let i = controlsLength; i < valueLength; i++) {
        this.push(this.makeControlFn(value?.[i]), options);
      }
    }

    if (valueLength < controlsLength) {
      for (let i = controlsLength - 1; i >= valueLength; i--) {
        this.removeAt(i, options);
      }
    }
  }
}
