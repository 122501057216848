import { formatCurrency } from '@angular/common';
import { Nullable, SelectOption } from '@lib-utils';
import { TagDisplayValueCallbackType, TagPrefixCallbackType } from './filter.interfaces';

/**
 * Can be used as `getOptionLabel | executeWith: abstractReactiveWithOptionsRef.optionsMap`
 */
export const getOptionLabel =
  (
    options?: Nullable<SelectOption[]> | Map<unknown, SelectOption>,
    hideValues: unknown[] = [],
  ): TagDisplayValueCallbackType =>
  (value: unknown) =>
    (options instanceof Map ? Array.from(options.values()) : options)?.find(
      (item) => item.value === value && !hideValues.includes(value),
    )?.label ?? '';

export const getCurrencyTagDisplayValue: TagDisplayValueCallbackType<number> = (value) =>
  value ? formatCurrency(value, 'ru', 'руб', 'symbol', '1.0-2') : null;

export const getDropdownRangeTagPrefix =
  (from: string, to: string): TagPrefixCallbackType =>
  (tagIndex: number) =>
    tagIndex === 0 ? from : to;
