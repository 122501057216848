<fnip-button
  class="nav-option-btn w-100"
  [appearance]="isCurrent ? 'appearance-light' : ''"
  [btnRouterLink]="!isExpandable && useRouting ? btnRouterLink : null"
  [btnRouterQueryParams]="btnRouterQueryParams"
  [actionCallback$]="actionCallback$"
>
  <tui-svg *ngIf="isExpandable" [class.expanded]="isExpanded" class="expand-icon mr-1" src="tuiIconChevronRightLarge" />

  <fnip-ellipsis-hint class="flex-1 text-start option" [content]="label" [isDisabled]="disableEllipsis" />
  <span *ngIf="hasCount" class="option-count ml-2">
    {{ count }}
  </span>
</fnip-button>
