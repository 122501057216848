import { TuiDayRange } from '@taiga-ui/cdk';
import pickBy from 'lodash-es/pickBy';
import { Nullable, RoleType, toISOString } from '@lib-utils';
import { SourceType, VerificationTaskType } from '@lib-verification/api';
import { VerificationAllDocumentStatus, VerificationGeneralTaskType } from '@lib-verification/api-middleware';

/** Тип задания - [Однотипные типы заданий] */
export const IDENTICAL_TASK_TYPES_MAP = new Map<VerificationTaskType, VerificationTaskType[]>([
  [
    VerificationTaskType.EmploymentContract,
    [
      VerificationTaskType.EmploymentContract2,
      VerificationTaskType.EmploymentContract3,
      VerificationTaskType.EmploymentContractMainJob,
    ],
  ],
  [
    VerificationTaskType.IncomeStatement,
    [
      VerificationTaskType.IncomeStatementPartTime,
      VerificationTaskType.IncomeStatementPartTime2,
      VerificationTaskType.IncomeStatementPartTime3,
    ],
  ],
  [
    VerificationTaskType.IncomeStatementBankFormat,
    [
      VerificationTaskType.IncomeStatementBankFormatPartTime,
      VerificationTaskType.IncomeStatementBankFormatPartTime2,
      VerificationTaskType.IncomeStatementBankFormatPartTime3,
    ],
  ],
  [
    VerificationTaskType.TaxReturn,
    [
      VerificationTaskType.TaxReturnPartTime,
      VerificationTaskType.TaxReturnPartTime2,
      VerificationTaskType.TaxReturnPartTime3,
    ],
  ],
  [
    VerificationTaskType.SelfEmployedCertificate,
    [
      VerificationTaskType.SelfEmployedCertificatePartTime,
      VerificationTaskType.SelfEmployedCertificatePartTime2,
      VerificationTaskType.SelfEmployedCertificatePartTime3,
    ],
  ],
  [
    VerificationTaskType.ProfessionalIncomeTaxCertificate,
    [
      VerificationTaskType.ProfessionalIncomeTaxCertificatePartTime,
      VerificationTaskType.ProfessionalIncomeTaxCertificatePartTime2,
      VerificationTaskType.ProfessionalIncomeTaxCertificatePartTime3,
    ],
  ],
  [VerificationTaskType.EmploymentHistory, [VerificationTaskType.ElectronicEmploymentHistory]],
]);

export class OperatorFilters {
  public sources: Nullable<SourceType[]>;
  public taskTypes: Nullable<VerificationTaskType[]>;
  public sidebarStatus: Nullable<VerificationAllDocumentStatus>;
  public documentStatuses: Nullable<VerificationAllDocumentStatus[]>;
  public verificatorIds: Nullable<number[]>;
  public dateRange: Nullable<TuiDayRange>;
  public firstName: Nullable<string>;
  public middleName: Nullable<string>;
  public lastName: Nullable<string>;
  public taskId: Nullable<string>;
  public applicationId: Nullable<string>;
  public generalTaskType: Nullable<VerificationGeneralTaskType>;

  static mapData(
    {
      sources,
      taskTypes,
      sidebarStatus,
      documentStatuses,
      verificatorIds,
      dateRange,
      firstName,
      middleName,
      lastName,
      taskId,
      applicationId,
      generalTaskType,
    }: OperatorFilters,
    role: RoleType,
  ) {
    const searchTaskTypes = taskTypes?.map((taskType) => {
      const identicalTaskTypes = IDENTICAL_TASK_TYPES_MAP.get(taskType);
      return identicalTaskTypes ? [taskType, ...identicalTaskTypes] : [taskType];
    });
    return pickBy({
      sources: sources?.join(',') || null,
      taskTypes: searchTaskTypes?.join(',') || null,
      documentStatuses:
        documentStatuses?.join(',') ||
        getDocumentStatuses(sidebarStatus ?? VerificationAllDocumentStatus.AllTasks, role).join(',') ||
        null,
      verificatorIds: verificatorIds?.join(',') || null,
      from: toISOString(dateRange?.from),
      to: toISOString(dateRange?.to),
      firstName,
      middleName,
      lastName,
      taskId: taskId?.toString(),
      applicationId: applicationId?.toString(),
      generalTaskType: generalTaskType !== VerificationGeneralTaskType.All ? generalTaskType : null,
      myTasks: (sidebarStatus === VerificationAllDocumentStatus.MyTasks).toString(),
    }) as Record<string, string>;
  }
}

export const getDocumentStatuses = (sidebarStatus: VerificationAllDocumentStatus, role: RoleType) => {
  if (sidebarStatus === VerificationAllDocumentStatus.AllTasks) return [];
  if (sidebarStatus === VerificationAllDocumentStatus.MyTasks) {
    if (role === RoleType.VerifierHead)
      return [
        VerificationAllDocumentStatus.RedirectedToSupervisor,
        VerificationAllDocumentStatus.Pending,
        VerificationAllDocumentStatus.Pause,
      ];
    if (role === RoleType.VerifierSupervisor) return [VerificationAllDocumentStatus.RedirectedToSupervisor];
    if (role === RoleType.Verificator) return [VerificationAllDocumentStatus.Pending];
    if (role === RoleType.SuperAdmin) return [];
  }
  return [sidebarStatus];
};
