import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TuiSvgModule } from '@taiga-ui/core';
import { ExecuteWithPipeModule, Nullable } from '@lib-utils';
import { ButtonComponent, ButtonModule, EllipsisHintComponent } from '@lib-widgets/core';

@Component({
  selector: 'fnip-list-sidebar-button',
  standalone: true,
  imports: [CommonModule, ExecuteWithPipeModule, ButtonModule, EllipsisHintComponent, TuiSvgModule],
  templateUrl: './list-sidebar-button.component.html',
  styleUrls: ['./list-sidebar-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListSidebarButtonComponent {
  @Input() label: Nullable<string>;

  @Input() hasCount = false;

  @Input() count: Nullable<number>;

  @Input() isCurrent: Nullable<boolean> = false;

  @Input() isExpandable: Nullable<boolean> = false;

  @Input() isExpanded: Nullable<boolean> = false;

  @Input() disableEllipsis = false;

  @Input() useRouting: Nullable<boolean>;

  @Input() btnRouterLink: ButtonComponent['btnRouterLink'] = ['.'];

  @Input() btnRouterQueryParams: ButtonComponent['btnRouterQueryParams'];

  @Input() actionCallback$: ButtonComponent['actionCallback$'];
}
