import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { shareReplay } from 'rxjs/operators';
import { ConfigService } from '@lib-config';
import { Nullable, RoleType } from '@lib-utils';
import { BreadcrumbComponent, PrivacyPolicyComponent } from '@lib-widgets/core';
import { ReactiveButtonDropdownModule } from '@lib-widgets/reactive-fields';
import { getLogout$, HasRolesPipe } from '@lib-mortgage/features/authorization';
import { ToolbarModule } from '@lib-mortgage/widgets/layout';
import { VerificationApiService, VerifierStatus } from '@lib-verification/api';
import { environment } from '../environments/environment';
import { VerificatorStatusComponent } from './widgets/verificator-status/verificator-status.component';

@Component({
  standalone: true,
  selector: 'fnip-app-component',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ToolbarModule,
    PrivacyPolicyComponent,
    ReactiveButtonDropdownModule,
    VerificatorStatusComponent,
    HasRolesPipe,
    BreadcrumbComponent,
    RouterOutlet,
  ],
})
export class AppComponent {
  readonly RoleType = RoleType;
  readonly verificationVersion = environment.frontVersion;
  readonly logout$ = getLogout$();
  readonly verificationApiService = inject(VerificationApiService);
  readonly hostUrl = inject(ConfigService).hostUrl;
  readonly verificator$ = this.verificationApiService.apiVerificationVerifierGet().pipe(shareReplay(1));

  readonly updateStatus$ = (id: Nullable<number>, status: VerifierStatus) =>
    this.verificationApiService.apiVerificationVerifierVerifierIdPut({
      verifierId: id!,
      verifierUpdateRequest: {
        status,
        ignoreStatusForTasks: false,
      },
    });
}
