import { Directive, Input, TemplateRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import isNil from 'lodash-es/isNil';
import { EMPTY, map, startWith } from 'rxjs';
import { Nullable } from '@lib-utils';
import { TagDisplayValueCallbackType, TagPrefixCallbackType, TagResetCallbackType } from './filter.interfaces';

@Directive({
  selector: '[fnipFilterControl]',
  standalone: true,
})
export class FilterControlDirective<TValue = unknown> {
  @Input({ required: true }) control: Nullable<FormControl<Nullable<TValue | TValue[]>>>;

  @Input() defaultValue: Nullable<TValue>;

  @Input() customTagTemplate: Nullable<TemplateRef<unknown>>;

  @Input() tagPrefix: Nullable<string>;

  // On tag (1) remove instead of [1,2] -> [2], makes [1,2] -> [null, 2]
  // Useful on input range
  @Input() resetArrayValuesToNull = false;

  // Overrides raw value in tag
  @Input() tagDisplayValueCallback: TagDisplayValueCallbackType<TValue>;

  @Input() tagPrefixCallback: TagPrefixCallbackType<TValue>;

  @Input() tagResetCallback: TagResetCallbackType;

  getTagPrefix = (tagIndex: number, tagValue: TValue) => {
    if (this.tagPrefixCallback) {
      return this.tagPrefixCallback(tagIndex, tagValue);
    }

    return this.tagPrefix;
  };

  get controlValue() {
    return this.control?.value;
  }

  get lastValue$() {
    return this.control?.valueChanges.pipe(startWith(this.controlValue)) ?? EMPTY;
  }

  get displayValues$() {
    return this.lastValue$.pipe(
      map(
        (lastValue) =>
          (Array.isArray(lastValue) ? lastValue : [lastValue])
            .map((value) => (this.tagDisplayValueCallback ? this.tagDisplayValueCallback(value!) : value))
            .flat(), // in case of displayValueCallback returns array
      ),
    );
  }

  get hasValue$() {
    return this.lastValue$.pipe(
      map((value) => {
        if (value instanceof Map || value instanceof Set) return value.size;
        if (Array.isArray(value)) return value.length;

        return isNil(this.defaultValue) ? Boolean(value) : value !== this.defaultValue;
      }),
    );
  }

  reset(tagIndex: number | null) {
    if (this.tagResetCallback) {
      return this.tagResetCallback(tagIndex);
    }

    if (tagIndex !== null && Array.isArray(this.controlValue)) {
      const filteredValue = this.resetArrayValuesToNull
        ? this.controlValue.map((val, index) => (index !== tagIndex ? val : (null as unknown as TValue)))
        : this.controlValue.filter((_val, index) => index !== tagIndex);

      return this.control?.reset(filteredValue.length ? filteredValue : undefined);
    }

    return this.control?.reset();
  }
}
